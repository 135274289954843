<template>
    <div class="wrapper register-wrapper">
        <div class="login-page">
            <div class="container p-0">
                <div class="main-title">
                    <h3 class="mb-0">{{ $t('login.login_title') }}</h3>
                </div>
                <div class="card card-pages">
                    <div class="form-title">
                        <h4>Welcome to Cheap Comforts</h4>
                    </div>
                    <div class="card-body">
                        <div v-if="showError" class="alert alert-danger msg">{{ error }}</div>
                        <div v-if="showSuccess" class="alert alert-success msg">{{ success }}</div>

                        <form class="form-horizontal m-t-30" action="#" method="POST" v-on:submit.prevent="login">
                            <div class="form-fields">
                                <div class="form-group">
                                    <div class="input-file">
                                        <!-- <label>{{ $t('login.email') }}</label> -->
                                        <input class="form-control" type="text" :placeholder="$t('login.enter_email')"
                                            autofocus v-model="email">
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="input-file">
                                        <!-- <label>{{ $t('login.password') }}</label> -->
                                        <input class="form-control" type="password"
                                            :placeholder="$t('login.enter_password')" name="password" autofocus
                                            v-model="password">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row my-3" style="justify-content: space-between;">
                                <div>
                                    <div class="checkbox checkbox-primary">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="customCheck1">
                                            <label class="custom-control-label" for="customCheck1">{{
                                                $t('login.remember_me') }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group text-center m-t-20 mb-2">
                                <div class="input-file">
                                    <button class="btn btn-primary btn-block btn-lg waves-effect waves-light"
                                        :class="{ disabled: isDisabled }" type="submit">{{
                                            $t('login.login_button') }}</button>
                                </div>
                            </div>

                            <!-- <div class="form-group row m-t-30 m-b-0 forget-account"
                                style="margin: 0px !important; justify-content: space-between;">
                                <div>
                                    <router-link to="/forgot-password" class="text-muted"><i
                                            class="fa fa-lock m-r-5"></i> {{ $t('login.forgot_password')
                                        }}</router-link>
                                </div>
                                <div class="text-right">
                                    <router-link to="/home/signup" class="text-muted "><i
                                            class="fa fa-user m-right-5"></i>{{ $t('login.create_account')
                                        }}</router-link>
                                </div>
                            </div> -->
                        </form>
                        <!-- Social Login Buttons -->
                        <div class="social-login text-center">
                            <router-link to="/forgot-password" class="btn"><i class="fa fa-lock m-r-5"></i> <span>{{
                                $t('login.forgot_password')
                                    }}</span></router-link>
                            <router-link to="/home/signup" class="btn"><i class="fa fa-user m-right-5"></i> <span>{{
                                    $t('login.create_account')}}</span>
                            </router-link>
                            <button class="btn" @click="AuthProvider('google')"><i
                                    style="background: linear-gradient(-120deg, #4285f4, #34a853, #fbbc05, #ea4335);-webkit-background-clip: text;-webkit-text-fill-color: transparent;"
                                    class="fa fa-google" aria-hidden="true"></i> <span>Login with Google</span></button>
                            <button class="btn" @click="AuthProvider('facebook')"><i style="color: #1877F2;"
                                    class="fa fa-facebook" aria-hidden="true"></i> <span>Login with
                                    Facebook</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API from '@/api';
import Vue from 'vue';

export default {
    name: 'LoginComponent',
    data() {
        return {
            email: '',
            password: '',
            showError: false,
            error: '',
            showSuccess: false,
            success: '',
            stayLogin: false,
            isDisabled: false,
        }
    },

    methods: {
        login() {
            if (this.email == '') {
                this.showError = true;
                this.error = 'Please type Email!';
            } else if (this.password == '') {
                this.showError = true;
                this.error = 'Please type Password!';
            } else if (this.password.length < 6) {
                this.showError = true;
                this.error = 'Password length should be min 6 characters!';
            } else {
                var re = /\S+@\S+\.\S+/;
                if (re.test(this.email)) {
                    this.showError = false;

                } else {
                    this.showError = true;
                    this.error = 'Please type valid Email Address!';
                }
            }
            let email = this.email
            let password = this.password
            let remember_be = this.remember_be
            let data = {
                email: email,
                password: password,
                remember_be: remember_be
            }
            this.disabled = true;
            API.loginAsGuest(
                data,
                data => {
                    if (typeof data.token !== "undefined") {
                        localStorage.setItem('isLoggedin', data.token)
                        localStorage.setItem('client_settings', JSON.stringify(data.client_settings))
                        localStorage.setItem('user', JSON.stringify(data.user))
                        localStorage.setItem('userPermissions', JSON.stringify(data.permission))
                        localStorage.setItem('password', this.password)
                        if (this.remember_be == 'true') {
                            localStorage.setItem('email', this.email)
                            localStorage.setItem('password', this.password)
                        }
                        // this.$router.push({ name: 'home' });
                        const originalUrl = localStorage.getItem('originalUrl');
                        if (originalUrl) {
                            // If original URL exists, redirect back to it
                            this.$router.push(originalUrl);
                            localStorage.removeItem('originalUrl'); // Remove the original URL from storage
                        } else {
                            // If no original URL, redirect to a default route
                            this.$router.push({ name: 'home' });
                        }
                    } else {
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: data.message,
                        })
                    }
                },
                err => {
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                }

            )
        },
        AuthProvider(provider) {
            var self = this;

            this.$auth
                .authenticate(provider)
                .then((response) => {
                    // self.SocialLogin(provider, response);
                })
                .catch((err) => {
                    if (err.message === "Auth popup window closed") {
                        this.showError = true;
                        this.error = "Authentication was cancelled. Please try again.";
                    } else {
                        console.error(err);
                    }
                });

            const handleAuthMessage = (event) => {
                if (event.data.type === 'authSuccess') {
                    const userEmail = event.data.email;
                    window.removeEventListener('message', handleAuthMessage); // Remove listener after handling message
                    // this.$router.push({ name: 'home' });
                    let data = {
                        email: event.data.email,
                        password: 'password',
                    }
                    API.loginAsGuest(
                        data,
                        data => {
                            if (typeof data.token !== "undefined") {
                                localStorage.setItem('isLoggedin', data.token)
                                localStorage.setItem('loginwithfb', '1')
                                localStorage.setItem('client_settings', JSON.stringify(data.client_settings))
                                localStorage.setItem('user', JSON.stringify(data.user))
                                localStorage.setItem('userPermissions', JSON.stringify(data.permission))
                                localStorage.setItem('password', this.password)
                                if (this.remember_be == 'true') {
                                    localStorage.setItem('email', this.email)
                                    localStorage.setItem('password', this.password)
                                }
                                // this.$router.push({ name: 'home' });
                                const originalUrl = localStorage.getItem('originalUrl');
                                if (originalUrl) {
                                    // If original URL exists, redirect back to it
                                    this.$router.push(originalUrl);
                                    localStorage.removeItem('originalUrl'); // Remove the original URL from storage
                                } else {
                                    // If no original URL, redirect to a default route
                                    this.$router.push({ name: 'home' });
                                }
                            } else {
                                this.$swal({
                                    type: 'error',
                                    title: 'Oops...',
                                    text: data.message,
                                })
                            }
                        },
                        err => {
                            this.$swal({
                                type: 'error',
                                title: 'Oops...',
                                text: err.message,
                            })
                        }

                    )

                } else if (event.data === 'authError') {
                    window.removeEventListener('message', handleAuthMessage); // Remove listener after handling message
                    this.showError = true;
                    this.error = "Social login failed. Please try again.";
                }
            };

            window.addEventListener('message', handleAuthMessage);
        },

    },

    mounted() {

    }
}

</script>
<style scoped>
.wrapper {
    position: relative;
}

.wrapper.register-wrapper {
    height: 92vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.my-wrapper {
    padding-top: 0px;
}

.alert.alert-danger.msg {
    margin-bottom: 10px !important;
}
.register-wrapper input[type=text], .register-wrapper input[type=date], .register-wrapper input[type=password], .register-wrapper input[type=tel] {
    border: none;
    padding: 4px 10px !important;
    border: 1px solid #a3a1a1;
    border-radius: 0;
    margin-bottom: 0 !important;
    margin-top: 0px !important;
}
.register-wrapper input[type=text]:focus, .register-wrapper input[type=date]:focus, .register-wrapper input[type=password]:focus, .register-wrapper input[type=tel]:focus {
    border: 2px #42aaff solid !important;
}

.login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 0 5px 0px #0000007a;
    padding-top: 25px;
    padding-bottom: 10px
}

.login-page .main-title {
    text-align: center;
    padding: 0 0 15px 5px;
    border-bottom: 1px solid #adadad;
    margin-bottom: 20px;
}

.login-page .main-title h3 {
    line-height: 1.2;
    font-size: 20px;
    font-weight: 700;
}

.login-page .card {
    border: 0;
    padding: 0 20px;
}

.login-page .card-body {
    padding: 0;
}

.login-page .card-body .form-group {
    margin-bottom: 0;
}

.login-page .card-body .form-group.row.my-3 {
    margin: 0;
}

.login-page .text-muted {
    margin-top: 20px;
}

.login-page .card .form-title {
    margin: 10px 0 10px;
}

.login-page .custom-control-label::after {
    top: 0;
}

.login-page .custom-checkbox .custom-control-label::before {
    position: absolute;
    top: 0;
}

.login-page .card-body .form-fields .form-group .input-file .form-control {
    padding: 0;
    border: 0 !important;
}

.login-page .card-body .form-fields {
    border: 1px solid #a3a1a1;
    border-radius: 10px;
    overflow: hidden;
}

.login-page .card-body .form-fields .form-group:first-child .input-file .form-control {
    border-bottom: 1px solid #a3a1a1 !important;
    border-radius: 10px 10px 0 0;
}

.login-page .card-body .form-fields .form-group:last-child .input-file .form-control {
    border-radius: 0 0 10px 10px;
}

.login-page .card-body .form-group .input-file .btn-primary.btn-block {
    border-radius: 10px !important;
}
.login-page .form-control {
    margin-bottom: 0;
}

.login-page .form-group {
    margin-bottom: 0;
}

.jumbotron {
    background-color: #fff !important;
    border-radius: .125rem !important;
    -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important;
}

.login-page .skin-light.color-grey-light,
.login-page .skin-light .color-grey-light,
.login-page .skin-light .badge-grey-light,
.login-page .skin-light .bg-grey-light {
    background-color: #fbfbfb !important;
}

.login-page .skin-light .mt-70 {
    margin-top: 70px !important;
}

.login-page .skin-light .jumbotron {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    width: 100%;
    height: 129px;
}

.login-page .skin-light {
    color: #4f4f4f;
}

.login-page .msg {
    margin: 0px 14px !important;
}

.social-menu {
    margin-left: 55%;
    /* margin-top: 50px; */
}

.social-menu ul {
    /* position: absolute; */
    top: 50%;
    left: 50%;
    padding: 0;
    margin: 0;
    transform: translate(-50%, -50%);
    display: flex;
}

.social-menu ul li {
    list-style: none;
    margin: 0 15px;
}

.social-menu ul li .fab {
    font-size: 30px;
    line-height: 60px;
    transition: .3s;
    color: #000;
}

.social-menu ul li .fab:hover {
    color: #fff;
}

.social-menu ul li button {
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    transition: .6s;
    box-shadow: 0 5px 4px rgba(0, 0, 0, .5);
}

.social-menu ul li button:hover {
    transform: translate(0, -10%);
}

.social-menu ul li:nth-child(1) button:hover {
    /* background-color: rgba(0, 0, 0, 0.829); */
    background-color: #E4405F;
}

.social-menu ul li:nth-child(2) button:hover {
    /* background-color: #E4405F; */
    background-color: #0857dc;
}

.social-menu ul li:nth-child(3) button:hover {
    /* background-color: #0077b5; */
    background-color: #ec4333
}

.social-menu ul li:nth-child(4) button:hover {
    /* background-color: #000; */
    background-color: #1da1f2;
}


@media (max-width: 991px) {
    .skin-light .mt-70 {
        margin-top: 150px !important;
    }
}

@media (min-width: 992px) {
    .skin-light .mt-70 {
        margin-top: 80px !important;
    }
}

@media (max-width: 767px) {
    .login-page .card .card-body {
        padding: 30px 0;
    }
}

.icons-row {
    align-items: center;
    justify-content: center;
}

.icons-row .social-menu {
    margin-left: 0;
    margin-top: 0;
    width: 100%;
}

.icons-row .social-menu ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    transform: none;
    top: 0;
    left: 0;
}

.m-right-5 {
    margin-right: 5px;
}

.social-login {
    margin-top: 30px;
}

.social-login .btn {
    width: 100%;
    margin-bottom: 10px;
    font-weight: 500;
    border-radius: 10px !important;
    display: flex;
    border: 1px solid #000;
}

.social-login .btn span {
    flex: 1 1 0% !important;
}
</style>